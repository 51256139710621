import React, { useEffect, useState } from 'react';
import { Checkbox } from '@blendle/lego';
import { translate, translateElement, getCurrencyWord } from 'instances/i18n';
import classNames from 'classnames';
import PaymentManager from 'managers/payment';
import Analytics from 'instances/analytics';
import { RECURRING_MINIMAL_AMOUNT } from 'app-constants';
import RecurringPaymentTriggered from 'components/dialogues/RecurringPaymentTriggered';
import { pipe, prop, anyPass, equals, propEq } from 'ramda';
import useUser from 'hooks/useUser';

const showRecurringToggle = pipe(
  prop('state'),
  anyPass([equals('recurring'), equals('norecurring_hascontracts')]),
);

const recurringEnabled = propEq('state', 'recurring');

const Recurring = () => {
  const user = useUser();
  const [recurringState, setRecurringState] = useState();
  const [showAutoTopUpEnabledDialog, setShowAutoTopUpEnabledDialog] = useState(false);

  useEffect(() => {
    PaymentManager.getRecurringContract(user)
      .then(PaymentManager.getRecurringState)
      .then(recurringData => setRecurringState(recurringData));
  }, []);

  const toggleRecurring = () => {
    PaymentManager.setRecurringContract(user, !recurringState.enabled)
      .then(PaymentManager.getRecurringState)
      .then(newRecurringState => {
        setRecurringState(newRecurringState);

        if (newRecurringState.enabled) {
          Analytics.track('Enabled Recurring Contract');

          const balanceAsNumber = Number(user.get('balance'));

          if (balanceAsNumber < RECURRING_MINIMAL_AMOUNT) {
            PaymentManager.upgradeRecurring(user)
              .then(() => {
                setShowAutoTopUpEnabledDialog(true);
              })
              .catch(err => {
                // We want to ignore XHR errors
                if (!err.status) {
                  throw err;
                }
              });
          }
        } else {
          Analytics.track('Disabled Recurring Contract');
        }
      });
  };

  if (showRecurringToggle(recurringState)) {
    const recurringClass = classNames('block recurring-block', recurringState.state);
    const recurringText = translate('payment.recurring.label', {
      currency: getCurrencyWord(),
      currency_plural: getCurrencyWord({ plural: true }),
    });

    return (
      <>
        <div className={recurringClass} data-testid="recurring-toggle-elements">
          <h2 className="title">{translateElement('payment.recurring.title')}</h2>
          <Checkbox
            id="recurring-toggle"
            name="recurring"
            checked={recurringEnabled(recurringState)}
            onChange={toggleRecurring}
          >
            {recurringText}
            <br />
            <span className="note">{translate('payment.recurring.label_note')}</span>
          </Checkbox>
        </div>
        {showAutoTopUpEnabledDialog && (
          <RecurringPaymentTriggered onClose={() => setShowAutoTopUpEnabledDialog(false)} />
        )}
      </>
    );
  }

  return null;
};

export default Recurring;
