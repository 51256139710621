// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".deleteAccount-qw3ZT{position:relative}.action-vX1f4{float:right}@media screen and (min-width: 600px){.action-vX1f4{position:absolute;right:0;top:15px}}.button-26s5r,.button-26s5r:hover{color:#948784;border:none;background:transparent}\n", ""]);
// Exports
exports.locals = {
	"deleteAccount": "deleteAccount-qw3ZT",
	"action": "action-vX1f4",
	"button": "button-26s5r"
};
module.exports = exports;
