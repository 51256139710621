import { useEffect, useState, useRef } from 'react';
import Settings from 'controllers/settings';
import UserOrdersCollection from 'collections/userOrders';
import useUser from 'hooks/useUser';

const fetchOrderProduct = order => {
  return order.getRelation('product', { accept: 'application/hal+json' }).catch(error => {
    // expired products (like subscriptions) return a 402
    if (error.status === 402) {
      return Promise.resolve(order.setEmbedded('product', error.data));
    }
    throw error;
  });
};

const useOrdersCollection = () => {
  const user = useUser();
  const [syncCount, setSyncCount] = useState(0);

  // Using a ref to keep the same instance of the backbone collection throughout the renders
  const orderCollectionRef = useRef(
    new UserOrdersCollection(null, {
      product: 'subscription',
      url: Settings.getLink('user_orders', {
        user_id: user.id,
        product: 'subscription',
      }),
    }),
  );

  useEffect(() => {
    orderCollectionRef.current.fetch({ accept: 'application/hal+json' });
  }, []);

  useEffect(() => {
    orderCollectionRef.current.on('sync', () => {
      const products = orderCollectionRef.current.map(fetchOrderProduct);

      Promise.all(products).then(() => {
        // Updating a "random" state variable so that we trigger a new render
        // after the Backbone collection has been updated.
        setSyncCount(count => count + 1);
      });
    });

    return () => {
      orderCollectionRef.current.off();
    };
  }, [syncCount]);

  return {
    orders: orderCollectionRef.current,
    fetchMore: () => orderCollectionRef.current.fetchNext(),
    hasMore: () => orderCollectionRef.current.hasNext(),
  };
};

export default useOrdersCollection;
