import _ from 'lodash';
import ByeBye from 'byebye';
import React from 'react';
import ApplicationState from 'instances/application_state';
import Auth from 'controllers/auth';
import { FEATURE_CAPABILITY_SUBSCRIPTION_CONFIGURATION } from 'app-constants';
import SubscriptionsManager from 'managers/subscriptions';
import Subscriptions from 'collections/subscriptions';
import { providerById, prefillSelector } from 'selectors/providers';
import DropdownView from 'views/helpers/dropdown';
import i18n from 'instances/i18n';
import Country from 'instances/country';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { capabilityIsLocked } from 'selectors/capabilities';
import connectionsTemplate from 'templates/modules/settings/connections';
import SubscriptionsActions from 'actions/SubscriptionsActions';
import ReactDOM from 'react-dom';
import Social from 'modules/settings/components/Social';
import { ThemeProvider } from '@blendle/lego';
import SubscriptionFormContainer from '../components/SubscriptionFormContainer';
import SubscriptionsContainer from '../containers/SubscriptionsContainer';

const ConnectionsView = ByeBye.View.extend({
  className: 'v-subscriptions v-social pane',

  events: {
    'click .btn-add-subscription': '_addSubscription',
  },

  initialize() {
    this.subscriptions = new Subscriptions();

    this.providers = SubscriptionsManager.getProviders();

    this.connectProviderIsHidden = capabilityIsLocked(
      FeatureCapabilitiesStore.getState(),
      FEATURE_CAPABILITY_SUBSCRIPTION_CONFIGURATION,
    );

    // Allow subscriptionViews to remove themselves.
    this.listenTo(this, 'removeView', this.removeView);
  },

  _renderAddSubscriptionDialogue(providerId) {
    const provider = prefillSelector(providerById)(providerId);

    const dialogue = (
      <SubscriptionFormContainer
        provider={provider}
        data={this._getDataForProvider(providerId)}
        onSuccess={() => {
          // Refresh subscriptions list
          SubscriptionsActions.fetchUserSubscriptions(Auth.getId(), Auth.getToken());

          this.getController().openSubscriptionsResult(providerId, 'success');

          // prevent the SubscriptionFormContainer from rendering
          return false;
        }}
      />
    );

    this.addView(
      new ByeBye.ReactView({
        renderComponent: () => dialogue,
      }),
      'dialogue',
    );

    this.getView('dialogue').render();
  },

  _addSubscription(e) {
    e.preventDefault();
    e.stopPropagation();

    const providerId = this.providersDropdown.getSelected();

    const providerLink = this._getLinkForProvider(providerId);

    if (providerLink) {
      ApplicationState.saveToCookie();

      window.location = providerLink.replace('{user_id}', Auth.getId());
    } else {
      this._renderAddSubscriptionDialogue(providerId);
    }
  },

  getProviders() {
    return (
      this.providers
        .filter(subscriptionProvider => prefillSelector(providerById)(subscriptionProvider.id))
        .map(subscriptionProvider => {
          const provider = prefillSelector(providerById)(subscriptionProvider.id);

          // For some providers, the subscription name is not the same as the provider name
          const name = subscriptionProvider.name || provider.name;

          return {
            name,
            country: i18n.getCountryCode(provider.language),
            ...subscriptionProvider,
          };
        })
        // Sort by country, then alphabetically
        .sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          const countryA = a.country === Country.getCountryCode();
          const countryB = b.country === Country.getCountryCode();

          if (countryA && !countryB) {
            return -1;
          }

          if (!countryA && countryB) {
            return 1;
          }

          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          }

          return 0;
        })
        .reduce((providers, provider) => {
          providers[provider.id] = provider.name;

          return providers;
        }, {})
    );
  },

  render() {
    this.el.innerHTML = connectionsTemplate({
      i18n: i18n.locale,
      user: this.options.model,
      providers: this.getProviders(),
    });

    this.providersDropdown = new DropdownView(this.getProviders(), {
      selected: this.options.provider,
    });
    this.addView(this.providersDropdown, 'providers_dropdown');
    this.el
      .querySelector('.dropdown-holder')
      .insertBefore(
        this.providersDropdown.render().el,
        this.el.querySelector('.dropdown-holder').querySelector('.missing-provider'),
      );

    this.display();

    const subscriptions = this.addView(
      new ByeBye.ReactView({
        renderComponent: () => <SubscriptionsContainer />,
      }),
      'subscriptions',
    );

    this.el.querySelector('.overview-holder').appendChild(subscriptions.render().el);

    if (this.connectProviderIsHidden) {
      this.el.querySelector('.subscriptions-wrapper').remove();
    }

    const socialConnectionsWrapper = this.el.querySelector('.social-connections-wrapper');

    ReactDOM.render(
      <ThemeProvider>
        <Social />
      </ThemeProvider>,
      socialConnectionsWrapper,
    );

    return this;
  },

  _getDataForProvider(providerId) {
    return _.find(this.providers, { id: providerId });
  },

  _getLinkForProvider(providerId) {
    return this._getDataForProvider(providerId).url;
  },
});

export default ConnectionsView;
