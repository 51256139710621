import moment from 'moment';
import { groupBy, values } from 'lodash';
import R from 'ramda';
import { PREMIUM_NON_CLOSABLE_PAID_PER_MONTH_SUBSCRIPTION } from 'app-constants';

export const isActive = ({ expired, startDate, endDate, isLegacy }) =>
  !expired &&
  (moment().isBetween(startDate, endDate) ||
    moment().isSameOrBefore(startDate) ||
    (isLegacy && !expired));

export const groupSubscriptionsByProvider = subscriptions =>
  values(groupBy(subscriptions, subscription => subscription.provider.uid));

export const isTrial = product => product && product.trial;

export const isRenew = product => product.renew === true;

export const inFuture = product => product.startDate.isAfter(moment());

/**
 * Returns true if the current offer is a promo, the successor product is not the same as
 * the current product.
 * @param  {Object} offer
 * @return {Boolean}
 */
export const isPromo = offer => {
  const successor = R.path(['_links', 'successor', 'href'], offer);
  const self = R.path(['_links', 'self', 'href'], offer);

  return !R.isNil(successor) && self !== successor;
};

export function getRemainingDays(subscription) {
  return Math.floor(subscription.endDate.diff(moment(), 'days', true));
}

const productUid = R.prop('uid');

export const isNonClosablePaidPerMonthSubscription = subscription =>
  R.contains(productUid(subscription), PREMIUM_NON_CLOSABLE_PAID_PER_MONTH_SUBSCRIPTION);
