import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import classNames from 'classnames';
import { getProviderLogoUrl } from 'helpers/providerHelpers';

function ProviderImage(props) {
  const imageClasses = classNames('provider', {
    [props.className]: props.className,
  });
  const src = getProviderLogoUrl(props.provider.id, 'logo.png');
  const providerName = props.provider.name;

  return <Image className={imageClasses} src={src} alt={providerName} />;
}

ProviderImage.propTypes = {
  className: PropTypes.string,
  provider: PropTypes.object.isRequired,
};

export default ProviderImage;
