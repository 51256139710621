import { compose } from '@blendle/recompose';
import renderNothingIfIsHidden from 'higher-order-components/renderNothingIfIsHidden';
import altConnect from 'higher-order-components/altConnect';
import DeleteAccountStore from 'stores/DeleteAccountStore';
import DeleteAccountActions from 'actions/DeleteAccountActions';
import DeleteAccountSupportDialog from 'modules/settings/components/DeleteAccountSupportDialog';

function mapStateToProps({ deleteAccountState }) {
  return {
    isHidden: !deleteAccountState.dialogOpen,
  };
}

mapStateToProps.stores = { DeleteAccountStore };

const actions = {
  onClose: DeleteAccountActions.toggleDeleteAccountDialog,
};

const enhance = compose(altConnect(mapStateToProps, actions), renderNothingIfIsHidden);

export default enhance(DeleteAccountSupportDialog);
