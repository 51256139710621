import { MASTER_OPT_OUT } from 'app-constants';
import { getUserProperty } from 'selectors/user';

/**
 *
 * @param {*} user
 * @returns {boolean}
 */
export const hasMasterOptOut = user => getUserProperty(user, MASTER_OPT_OUT);

const isPropertyLoading = (loadingProperties, propertyName) => loadingProperties.has(propertyName);

export const isLoading = (user, loadingProperties, propertyName) =>
  hasMasterOptOut(user) || isPropertyLoading(loadingProperties, propertyName);
