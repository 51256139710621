import Environment from 'environment';
import { getUtmString, getRefreshTokenIfDevelopmentEnv, buildQueryString } from './helpers';

function getSearchParams({ successPath, internalLocation }) {
  const utmString = getUtmString();
  const refreshToken = getRefreshTokenIfDevelopmentEnv();

  const searchParamsFragments = [
    refreshToken && `refreshToken=${refreshToken}`,
    `successPath=${encodeURIComponent(successPath)}`,
    ...[internalLocation && `internalLocation=${internalLocation}`],
    utmString,
  ];

  return buildQueryString(searchParamsFragments.filter(Boolean));
}

/**
 * To create the uri for the payment page where you can buy credits.
 * The internal location is required, itemId is optional
 * @param {string} internalLocation
 * @param {object} options
 * @param {string} [options.itemId]
 */
export function getBuyCreditsPageUrl(internalLocation, { itemId } = {}) {
  const successPathQueryParam = '?didCreditsTopup=true';
  const successPath = itemId
    ? `/item/${itemId}${successPathQueryParam}`
    : `/kiosk/issue${successPathQueryParam}`;

  const searchParams = getSearchParams({ successPath, internalLocation });

  return [Environment.paymentUri, '/buy-credits', searchParams].join('');
}
