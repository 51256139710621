import React from 'react';
import { translate, translateElement, getCountryCode } from 'instances/i18n';
import { getExceptionForCountry } from 'helpers/countryExceptions';
import getSubscriptionOverviewPageUri from 'helpers/paymentPageUrl/getSubscriptionOverviewPageUri';
import UpdatedTermsAndConditionsLink from './UpdatedTermsAndConditionsLink';

const TermsAndConditions = () => {
  const showUpdatedTAndCAnnouncement = getExceptionForCountry(
    getCountryCode(),
    'showNewTermsAndConditionsAnnouncement',
    false,
  );

  return (
    <div id="termsandconditions" className="l-text pane termsandconditions">
      <h1 className="title">{translate('about.terms_and_conditions.title')}</h1>
      {showUpdatedTAndCAnnouncement && <UpdatedTermsAndConditionsLink />}
      {translateElement('about.terms_and_conditions.contents', [getSubscriptionOverviewPageUri()])}
      <p className="end">•••</p>
    </div>
  );
};

export default TermsAndConditions;
