/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { shape, bool, string, func } from 'prop-types';
import { translate } from 'instances/i18n';
import classNames from 'classnames';
import FacebookStore from 'stores/FacebookStore';
import CookieConsentStore from 'stores/CookieConsentStore';
import AuthStore from 'stores/AuthStore';
import { STATUS_PENDING } from 'app-constants';
import altConnect from 'higher-order-components/altConnect';
import FacebookActions from 'actions/FacebookActions';
import ConsentNeededHint from 'components/facebookConnect/CookieConsentNeeded/ConsentNeededHint';
import styledModule from 'helpers/styledModule';
import CSS from './styles.scss';

const Container = styledModule.div('container');
const Header = styledModule.h2('header');
const Title = styledModule.h3('title');
const FacebookRow = styledModule('li')('row', 'facebook');
const ResetPassword = styledModule.p('reset-password');
const StyledConsentNeededHint = styledModule(ConsentNeededHint)(CSS.consentNeededHint);

const Social = ({
  user,
  facebookConnected,
  facebookLoading,
  hasGivenConsent,
  facebookError,
  onToggleFacebook,
}) => {
  const onClick = () => {
    if (!hasGivenConsent) {
      return;
    }

    onToggleFacebook(user);
  };

  const facebookClasses = classNames(
    'btn-toggle',
    { off: !facebookConnected },
    { 's-inactive': !user.get('has_password') || !hasGivenConsent },
    { loading: facebookLoading },
  );

  const consentNeededMessage = !hasGivenConsent ? <StyledConsentNeededHint /> : null;

  const resetPassword = !user.get('has_password') ? (
    <ResetPassword
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: translate('app.settings.social.reset_password'),
      }}
    />
  ) : null;

  const error = facebookError ? <p className="error-message">{facebookError}</p> : null;

  return (
    <Container>
      <Header>{translate('settings.social.title')}</Header>
      <Title>{translate('settings.social.authorized_following')}</Title>
      <ul>
        <FacebookRow>
          <span className={facebookClasses} onClick={onClick} />
          {translate('settings.social.options.facebook')}
          {consentNeededMessage}
          {resetPassword}
          {error}
        </FacebookRow>
      </ul>
    </Container>
  );
};

Social.propTypes = {
  user: shape({ get: func.isRequired }).isRequired,
  facebookConnected: bool.isRequired,
  facebookLoading: bool.isRequired,
  hasGivenConsent: bool,
  facebookError: string,
  onToggleFacebook: func.isRequired,
};

Social.defaultProps = {
  facebookError: undefined,
  hasGivenConsent: false,
};

function mapStateToProps({ facebookState, authState, cookieConsentState }) {
  const { connected, status, errorMessage } = facebookState;
  const { user } = authState;
  const { hasGivenConsent } = cookieConsentState;

  return {
    onToggleFacebook: FacebookActions.toggleFacebook,
    facebookConnected: connected,
    facebookLoading: status === STATUS_PENDING,
    facebookError: errorMessage,
    hasGivenConsent,
    user,
  };
}

mapStateToProps.stores = {
  FacebookStore,
  AuthStore,
  CookieConsentStore,
};

export default altConnect(mapStateToProps)(Social);
