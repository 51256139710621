// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header--x3Ad{margin-bottom:20px !important}.paragraph-3R2Ns{margin-bottom:10px}.toggles-DBwE4{padding:20px 0 55px}.toggle-CyJ7y{float:right;transition:opacity 0.3s ease-out}.loading-3A8XG{opacity:0.8}\n", ""]);
// Exports
exports.locals = {
	"header": "header--x3Ad",
	"paragraph": "paragraph-3R2Ns",
	"toggles": "toggles-DBwE4",
	"toggle": "toggle-CyJ7y",
	"loading": "loading-3A8XG"
};
module.exports = exports;
