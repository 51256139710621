import React from 'react';
import { func } from 'prop-types';
import { Dialog, DialogTitle, DialogBody, Button, ButtonGroup } from '@blendle/lego';
import { translate } from 'instances/i18n';
import Link from 'components/Link';

const linkToFaq =
  'https://www.blendle.support/hc/nl/articles/115000241212-Hoe-kan-ik-mijn-abonnement-op-Blendle-Premium-stopzetten-';

function DeleteAccountSupportDialog({ onClose }) {
  return (
    <Dialog onClose={onClose}>
      <DialogTitle>
        {translate('settings.profile.delete_account.support_dialoag.title')}
      </DialogTitle>
      <DialogBody>
        <p>{translate('settings.profile.delete_account.support_dialoag.message')}</p>
        <ButtonGroup vertical>
          <Button component={Link} href={linkToFaq}>
            {translate('settings.profile.delete_account.support_dialoag.contact_link')}
          </Button>
          <Button color="cappuccino" onClick={onClose}>
            {translate('settings.profile.delete_account.support_dialoag.close')}
          </Button>
        </ButtonGroup>
      </DialogBody>
    </Dialog>
  );
}

DeleteAccountSupportDialog.propTypes = {
  onClose: func.isRequired,
};

export default DeleteAccountSupportDialog;
