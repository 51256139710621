import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import classNames from 'classnames';
import { translate } from 'instances/i18n';
import { convertToLandingProjectURL } from 'libs/middleware/routerRedirectToLanding';
import styledModule from 'helpers/styledModule';
import BalanceLabel from 'components/BalanceLabel';
import CSS from './styles.scss';

const StyledBalanceLabel = styledModule(BalanceLabel)(CSS.balanceLabelBase);
const StyledBalanceLabelDesktop = styledModule(StyledBalanceLabel)(CSS.balanceLabelDesktop);

export default function Sidebar({
  isMobileViewport,
  onClickSidebar,
  isCollapsed,
  labEnabled,
  showMicropaymentsWallet,
  showCreditsWallet,
  showCoupons,
}) {
  const className = classNames('sidebar left', isCollapsed && 'isCollapsed');
  const walletClassList = classNames(['lnk', 'wallet']);
  const balanceLabel = isMobileViewport ? <StyledBalanceLabel /> : <StyledBalanceLabelDesktop />;

  return (
    <div className={className}>
      <Link
        className="lnk profile"
        aliases={['/settings']}
        href="/settings/profile"
        onClick={onClickSidebar}
      >
        {translate('settings.profile.title')}
      </Link>
      <Link className="lnk emails" href="/settings/emails" onClick={onClickSidebar}>
        {translate('settings.emails.title')}
      </Link>
      <Link className="lnk social" href="/settings/connections" onClick={onClickSidebar}>
        {translate('settings.social.title')}
      </Link>
      {showMicropaymentsWallet && (
        <Link className={walletClassList} href="/settings/wallet" onClick={onClickSidebar}>
          {translate('settings.wallet.title')}
          {balanceLabel}
        </Link>
      )}
      {showCreditsWallet && (
        <Link className={walletClassList} href="/settings/credits" onClick={onClickSidebar}>
          {translate('settings.creditsWallet.menu-title')}
          {balanceLabel}
        </Link>
      )}
      {showCoupons && (
        <Link
          className="lnk coupons"
          href={convertToLandingProjectURL('/actie')}
          onClick={onClickSidebar}
        >
          {translate('settings.coupons.title')}
        </Link>
      )}
      <Link
        className="lnk termsandconditions"
        href="/settings/termsandconditions"
        onClick={onClickSidebar}
      >
        {translate('about.terms_and_conditions.navigation')}
      </Link>
      {labEnabled && (
        <Link className="lnk lab" href="/settings/lab" onClick={onClickSidebar}>
          Lab
        </Link>
      )}
    </div>
  );
}

Sidebar.propTypes = {
  isMobileViewport: PropTypes.bool.isRequired,
  labEnabled: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  onClickSidebar: PropTypes.func.isRequired,
  showMicropaymentsWallet: PropTypes.bool.isRequired,
  showCreditsWallet: PropTypes.bool.isRequired,
  showCoupons: PropTypes.bool.isRequired,
};

Sidebar.defaultProps = {
  labEnabled: false,
  isCollapsed: false,
};
