import { toRenderProps } from '@blendle/recompose';
import { addCapabilityIsLocked } from 'higher-order-components/withCapabilityIsLocked';
import { func, string } from 'prop-types';

const CapabilityIsLocked = toRenderProps(addCapabilityIsLocked);

CapabilityIsLocked.propTypes = {
  capability: string.isRequired,
  children: func.isRequired,
};

export default CapabilityIsLocked;
