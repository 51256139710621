// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media (min-width: 375px){.wrapper-9LGu5{display:flex}}.title-3JviI{font-family:\"GT-Walsheim\",sans-serif;color:#333;font-size:18px;font-weight:500;padding:0}.balanceLabel-2Cc-9{margin-top:4px}@media (min-width: 375px){.balanceLabel-2Cc-9{margin-left:8px;position:relative;top:-2px;margin-top:0}}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper-9LGu5",
	"title": "title-3JviI",
	"balanceLabel": "balanceLabel-2Cc-9"
};
module.exports = exports;
