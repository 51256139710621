import React from 'react';
import { func, object } from 'prop-types';
import { compose } from 'ramda';
import { MASTER_OPT_OUT } from 'app-constants';
import { translate } from 'instances/i18n';
import Button from 'components/Button';
import { hasMasterOptOut } from './helpers';

/**
 * @typedef {Object} Values
 * @property {string} label
 * @property {string} buttonText
 * @property {string} classes
 */

/**
 * @param {boolean} isMasterOptOut
 * @return {Values}
 */
const getMasterOptOutOptions = isMasterOptOut =>
  isMasterOptOut
    ? {
        label: 'settings.emails.killswitch.off',
        buttonText: 'settings.emails.killswitch.turn_on',
        classes: 'email-kill-switch-btn-off',
      }
    : {
        label: 'settings.emails.killswitch.on',
        buttonText: 'settings.emails.killswitch.turn_off',
        classes: 'email-kill-switch-btn-on',
      };

/**
 * @param {*} user
 * @return {() => Values}
 */
const getOptions = compose(
  getMasterOptOutOptions,
  hasMasterOptOut,
);

function MasterOptOut({ user, onToggleOption }) {
  const { label, classes, buttonText } = getOptions(user);

  return (
    <div className="email-kill-switch">
      {translate(label)}
      <Button
        className={`email-kill-switch-btn s-small btn-green ${classes}`}
        onClick={() => onToggleOption(MASTER_OPT_OUT)}
      >
        {translate(buttonText)}
      </Button>
    </div>
  );
}

MasterOptOut.propTypes = {
  onToggleOption: func.isRequired,
  user: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default MasterOptOut;
