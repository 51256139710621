import ByeBye from 'byebye';
import React from 'react';
import ReactDOM from 'react-dom';
import { RECURRING_MINIMAL_AMOUNT } from 'app-constants';
import RequestManager from 'managers/request';
import Auth from 'controllers/auth';
import Analytics from 'instances/analytics';
import CloseView from 'views/helpers/button_close';
import ProfileView from 'modules/settings/views/profile';
import ConnectionsView from 'modules/settings/views/connections';
import TermsAndConditionsView from 'modules/about/components/TermsAndConditions';
import LabContainer from 'modules/settings/containers/LabContainer';
import PaymentManager from 'managers/payment';
import i18n from 'instances/i18n';
import UsersManager from 'managers/users';
import { isObject } from 'lodash';
import RecurringPaymentTriggered from 'components/dialogues/RecurringPaymentTriggered';
import DialogController from 'controllers/dialogues';
import EmailsContainer from 'modules/settings/containers/EmailsContainer';
import SubscriptionsManager from 'managers/subscriptions';
import ResendConfirmationEmailDialogue from 'components/dialogues/ResendConfirmationEmail';
import hasPrivateLabAccess from 'helpers/hasPrivateLabAccess';
import { getException } from 'helpers/countryExceptions';
import SegmentAnalytics from 'instances/segmentAnalytics';
import SubscriptionsResultContainer from '../components/SubscriptionResultContainer';
import SettingsView from '../views/settings';
import MicropaymentWallet from '../components/MicropaymentWallet';
import CreditsWallet from '../components/CreditsWallet';

const SettingsController = ByeBye.View.extend({
  requireAuth() {
    return Auth.requireSignup();
  },

  setRootView(view) {
    this._rootView = view;
  },

  getRootView() {
    return this._rootView;
  },

  addView(view, name) {
    const root = this.getRootView();
    if (root.getView(name)) {
      const { el } = root.getView(name);
      if (el) {
        ReactDOM.unmountComponentAtNode(el);
        el.parentNode.removeChild(el);
      }
    }
    view.setController(this);
    return root.addView(view, name);
  },

  initialize(options) {
    this.options = options;

    this.model = Auth.getUser();

    // Add main view
    this.setRootView(
      new SettingsView({
        main: options.area,
        closeView: false,
        onClose: options.onClose,
        user: this.model,
        el: options.target,
      }),
    );

    // Make sure we don't get back to the kiosk after closing the settings
    this.getRootView().addView(
      new CloseView({
        onClick: options.onClose,
      }),
    );

    // Bind events for profile view
    this.listenTo(this.getRootView(), 'reloadUser', this._reloadUser);

    this.listenTo(i18n, 'switch', () => {
      this.getRootView().render();
    });
  },

  afterUnload() {
    RequestManager.abort('settings');

    this.stopListening(i18n);
  },
  openProfile(action) {
    RequestManager.abort('settings');

    // Always get a fresh user model? No, right? TODO
    this.model = Auth.getUser();

    this.addView(new ProfileView({ model: this.model }), 'pane');

    if (action) {
      this._performAction(action);
    }

    Analytics.track('View Settings:profile');
    SegmentAnalytics.page({ pageName: 'Settings - Profile' });

    return this.getRootView().render();
  },
  openEmails(query) {
    RequestManager.abort('settings');

    this._emailOptInQuery(query);

    this.addView(
      new ByeBye.ReactView({
        renderComponent: () => <EmailsContainer />,
      }),
      'pane',
    );

    Analytics.track('View Settings:emails');
    SegmentAnalytics.page({ pageName: 'Settings - Email opt-ins' });

    return this.getRootView().render();
  },

  openWallet() {
    RequestManager.abort('settings');
    Analytics.track('View Settings:wallet');
    SegmentAnalytics.page({ pageName: 'Settings - Wallet' });

    this.addView(
      new ByeBye.ReactView({
        renderComponent: () => <MicropaymentWallet />,
      }),
      'pane',
    );

    this.getRootView().render();
  },

  openCredits() {
    RequestManager.abort('settings');
    Analytics.track('View Settings:wallet');
    SegmentAnalytics.page({ pageName: 'Settings - Wallet' });

    this.addView(
      new ByeBye.ReactView({
        renderComponent: () => <CreditsWallet />,
      }),
      'pane',
    );

    this.getRootView().render();
  },

  openRecurringActivate() {
    PaymentManager.getRecurringContract(this.model)
      .then(PaymentManager.getRecurringState)
      .then((recurringData) => {
        const { state } = recurringData;
        let redirect = '/payment';
        if (state === 'norecurring_hascontracts' || state === 'recurring') {
          redirect = 'payment/recurring/activate';
        }

        ByeBye.history.navigate(
          redirect,
          {
            trigger: true,
            replace: true,
          },
          {
            returnUrl: '/settings/wallet',
          },
        );
        this.openWallet();
        this.onRecurringChanged(recurringData);
      });
  },

  onRecurringChanged(recurring = {}) {
    // If the user enables recurring contracts, and their current balance is below the limit,
    // we should do an upgrade
    const balance = Number(this.model.get('balance'));
    if (recurring.enabled === true && balance < RECURRING_MINIMAL_AMOUNT) {
      PaymentManager.upgradeRecurring(Auth.getUser())
        .then(() => {
          this._showRecurringTriggeredDialog();
        })
        .catch((err) => {
          // We want to ignore XHR errors
          if (!err.status) {
            throw err;
          }
        });
    }
  },
  openConnections(provider) {
    RequestManager.abort('settings');

    this.addView(
      new ConnectionsView({
        model: this.model,
        provider: typeof provider !== 'string' ? null : provider,
      }),
      'pane',
    );

    Analytics.track('View Settings:connections');
    SegmentAnalytics.page({ pageName: 'Settings - Connections' });

    return this.getRootView().render();
  },

  openSubscriptionsResult(providerId, status) {
    const close = DialogController.openComponent(
      <SubscriptionsResultContainer
        providerId={providerId}
        status={status}
        onClose={() => {
          close();
        }}
      />,
    );

    return this.openConnections(providerId);
  },

  openSubscriptionCallback(provider, code) {
    const authCode = isObject(code) ? code.code || code.token || code.st : code;

    SubscriptionsManager.addSubscriptionWithAuthorizationCode(provider, authCode).then(
      () => {
        Auth.fetchUser().then(() => {
          ByeBye.history.navigate(`settings/connections/${provider}/success`, {
            trigger: true,
          });
        });
      },
      (response) => {
        let path = 'failed';
        if (response.data.message === 'Subscription already linked') {
          path = 'already_linked';
        } else if (response.data.message === 'No valid subscription found') {
          path = 'no_valid_subscription';
        }
        ByeBye.history.navigate(`settings/connections/${provider}/${path}`, {
          trigger: true,
        });
      },
    );
  },

  openResetPassword() {
    DialogController.resetPassword(Auth.getUser());
  },

  openTermsAndConditions() {
    RequestManager.abort('settings');

    this.addView(
      new ByeBye.ReactView({
        renderComponent: () => <TermsAndConditionsView />,
      }),
      'pane',
    );

    Analytics.track('View Settings:termsandconditions');
    SegmentAnalytics.page({ pageName: 'Settings - Terms and Conditions' });

    return this.getRootView().render();
  },

  openLab() {
    RequestManager.abort('settings');

    const labEnabled = getException('showPublicLab', false);
    if (!labEnabled && !hasPrivateLabAccess(Auth.getUser())) {
      return ByeBye.history.navigate('/404', { trigger: true });
    }

    this.addView(
      new ByeBye.ReactView({
        renderComponent: () => <LabContainer />,
      }),
      'pane',
    );

    Analytics.track('View Settings:lab');
    SegmentAnalytics.page({ pageName: 'Settings - Lab' });

    return this.getRootView().render();
  },

  _emailOptInQuery(query) {
    const flags = [
      'alerts',
      'new_edition',
      'read_later',
      'digest',
      'weekly_digest',
      'magazine_digest',
      'marketing',
      'followers',
      'tips',
      'announcements',
      'survey',
    ];

    flags
      .filter((flag) => query[flag])
      .forEach((flag) => {
        Auth.getUser()
          .saveProperty(`${flag}_opt_out`, false)
          .then(() => {
            Analytics.track(`Opt In: ${flag}`);
          });
      });
  },

  _showRecurringTriggeredDialog() {
    const close = DialogController.openComponent(
      <RecurringPaymentTriggered
        onClose={() => {
          // Refresh balance, just in case
          Auth.fetchUser().then(() => {
            Auth.getUser().trigger('change:balance');
          });

          close();
        }}
      />,
    );
  },

  _reloadUser(actor, changedAttribute) {
    Auth.fetchUser().then((user) => {
      // Since the new Avatar url is exactly the same as the old one, a change won't be triggered
      // even though it needs to for the rest of the application to know the user has changed.
      user.trigger(changedAttribute ? `change:${changedAttribute}` : 'change');
      actor.trigger('userReloaded', user);
    });
  },

  _performAction(action) {
    if (action === 'reconfirm') {
      UsersManager.resendConfirmationEmail(Auth.getId()).then(
        () => {
          const onClose = DialogController.openComponent(
            <ResendConfirmationEmailDialogue
              onClose={() => onClose()}
              email={Auth.getUser().get('email')}
            />,
          );
        },
        () => {
          this.getRootView().error({
            title: i18n.locale.app.error.o_oh,
            message: i18n.locale.settings.emails.confirmation_send_failed,
          });
        },
      );
    }
  },
});

export default SettingsController;
