import React from 'react';
import { translate } from 'instances/i18n';
import { Button } from '@blendle/lego';
import styledModule from 'helpers/styledModule';
import Link from 'components/Link';
import { getBuyCreditsPageUrl } from 'helpers/paymentPageUrl/getBuyCreditsPageUrl';
import { INTERNAL_LOCATION } from 'app-constants';
import Balance from '../Balance';
import Purchases from '../Purchases';
import Transactions from '../Transactions';
import CSS from './styles.scss';

const BuyCreditsButton = styledModule(Button)(CSS.buyCreditsButton);

const CreditsWallet = () => (
  <div className="v-wallet pane">
    <div className="container">
      <h2 className="header">{translate('settings.creditsWallet.title')}</h2>
      <Balance data-testid="balance" />
      <Link href={getBuyCreditsPageUrl(INTERNAL_LOCATION.SETTINGS)}>
        <BuyCreditsButton color="cash-green">
          {translate('settings.buttons.increase_credits_balance')}
        </BuyCreditsButton>
      </Link>
      <div className="overviews">
        <Purchases />
        <Transactions />
      </div>
    </div>
  </div>
);

export default CreditsWallet;
