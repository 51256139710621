import React from 'react';
import { func, bool, string } from 'prop-types';
import ToggleButton from 'components/buttons/Toggle';
import { translate } from 'instances/i18n';

export default function Email({ onToggleOption, propertyName, active, loading }) {
  return (
    <li className="row">
      <ToggleButton checked={!active} onToggle={onToggleOption} inactive={loading} />
      {translate(`settings.emails.options.${propertyName}`)}
    </li>
  );
}
Email.propTypes = {
  onToggleOption: func.isRequired,

  propertyName: string.isRequired,
  active: bool.isRequired,
  loading: bool.isRequired,
};
