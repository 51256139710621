import React from 'react';
import { string } from 'prop-types';

const TransactionRow = ({ date, text }) => (
  <li className="deposit">
    {date && <div className="date">{date}</div>}
    <div className="deposit">{text}</div>
  </li>
);

TransactionRow.propTypes = {
  date: string,
  text: string.isRequired,
};

TransactionRow.defaultProps = {
  date: undefined,
};

export default TransactionRow;
