import React from 'react';
import { func } from 'prop-types';
import { STATUS_OK } from 'app-constants';
import altConnect from 'higher-order-components/altConnect';
import SubscriptionsActions from 'actions/SubscriptionsActions';
import SubscriptionsStore from 'stores/SubscriptionsStore';
import AuthStore from 'stores/AuthStore';
import { isNonClosablePaidPerMonthSubscription } from 'selectors/subscriptions';
import DeleteAccount from '../components/DeleteAccount';

class DeleteAccountContainer extends React.Component {
  static propTypes = {
    fetchUserSubscriptions: func.isRequired,
  };

  componentDidMount() {
    const { user } = AuthStore.getState();

    this.props.fetchUserSubscriptions(user.id);
  }

  render() {
    return <DeleteAccount {...this.props} />;
  }
}

export function mapStateToProps({ authState, subscriptionsState }) {
  const { user } = authState;
  const { status, subscriptions } = subscriptionsState;

  const isReady = status === STATUS_OK;
  const hasNonClosableSubscription = subscriptions.some(
    subscription => !subscription.can_be_closed_in_blendle,
  );
  const hasPassword = user.attributes.has_password;

  const hasNonClosablePaidPerMonthSubscription = subscriptions.some(subscription =>
    isNonClosablePaidPerMonthSubscription(subscription),
  );

  return {
    mayDeleteAccount:
      isReady &&
      hasPassword &&
      !hasNonClosableSubscription &&
      !hasNonClosablePaidPerMonthSubscription,
    hasNonClosableSubscription: hasNonClosableSubscription && isReady,
  };
}

mapStateToProps.stores = { AuthStore, SubscriptionsStore };

export default altConnect(mapStateToProps, {
  fetchUserSubscriptions: SubscriptionsActions.fetchUserSubscriptions.defer,
})(DeleteAccountContainer);
