import React, { useState } from 'react';
import withViewportSize from 'higher-order-components/withViewportSize';
import { getException } from 'helpers/countryExceptions';
import hasPrivateLabAccess from 'helpers/hasPrivateLabAccess';
import Analytics from 'instances/analytics';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { hasCapability } from 'selectors/capabilities';
import {
  FEATURE_CAPABILITY_MICROPAYMENTS,
  FEATURE_CAPABILITY_UPSELL_TO_PREMIUM_CTA,
  FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS,
  INTERNAL_LOCATION,
} from 'app-constants';
import { bool } from 'prop-types';
import useAlt from 'hooks/useAlt';
import useUser from 'hooks/useUser';
import SidebarButton from '../components/SidebarButton';
import Sidebar from '../components/Sidebar';

const SidebarContainer = ({ isMobileViewport }) => {
  const user = useUser();
  const featureCapabilitiesState = useAlt([FeatureCapabilitiesStore], ([state]) => state);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const labsEnabled = getException('showPublicLab', false) || hasPrivateLabAccess(user);

  const showCoupons = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_UPSELL_TO_PREMIUM_CTA,
  );
  const showMicropaymentsWallet = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_MICROPAYMENTS,
  );
  const showCreditsWallet = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS,
  );

  const toggleIsCollapsed = () => {
    Analytics.track(`Sidebar ${isCollapsed ? 'Opened' : 'Closed'}`, {
      internal_location: INTERNAL_LOCATION.SETTINGS,
    });

    setIsCollapsed(!isCollapsed);
  };

  const isMobileAndCollapsed = isMobileViewport && isCollapsed;

  return (
    <>
      {isMobileViewport && (
        <SidebarButton onClick={toggleIsCollapsed} isCollapsed={isMobileAndCollapsed} />
      )}
      <Sidebar
        data-testid="sidebar"
        labEnabled={labsEnabled}
        onClickSidebar={toggleIsCollapsed}
        isCollapsed={isMobileAndCollapsed}
        showMicropaymentsWallet={showMicropaymentsWallet}
        showCreditsWallet={showCreditsWallet}
        showCoupons={showCoupons}
        isMobileViewport={isMobileViewport}
      />
    </>
  );
};

SidebarContainer.propTypes = {
  isMobileViewport: bool.isRequired,
};

export default withViewportSize({ debounce: 100 })(SidebarContainer);
