import { useEffect, useState, useRef } from 'react';
import Settings from 'controllers/settings';
import TransactionsCollection from 'collections/transactions';
import useUser from 'hooks/useUser';

const usePurchasesCollection = () => {
  const user = useUser();
  const [syncCount, setSyncCount] = useState(0);

  // Using a ref to keep the same instance of the backbone collection throughout the renders
  const purchasesCollectionRef = useRef(
    new TransactionsCollection(null, {
      url: Settings.getLink('transactions', { user_id: user.id }),
    }),
  );

  useEffect(() => {
    purchasesCollectionRef.current.fetch();
  }, []);

  useEffect(() => {
    purchasesCollectionRef.current.on('sync', () => {
      // Updating a "random" state variable so that we trigger a new render
      // after the Backbone collection has been updated.
      setSyncCount(count => count + 1);
    });

    return () => {
      purchasesCollectionRef.current.off();
    };
  }, [syncCount]);

  return {
    purchases: purchasesCollectionRef.current,
    fetchMore: () => purchasesCollectionRef.current.fetchNext(),
    hasMore: () => purchasesCollectionRef.current.hasNext(),
  };
};

export default usePurchasesCollection;
