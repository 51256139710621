/* eslint-disable react/forbid-prop-types */
import moment from 'moment';
import { translate } from 'instances/i18n';
import React from 'react';
import PropTypes from 'prop-types';
import { providerById, prefillSelector } from 'selectors/providers';
import { getManifestBody, getTitle, getContentAsText } from 'helpers/manifest';
import PurchaseRow from '../PurchaseRow';

const PurchasesList = ({ purchases, onLoadMore, hasMore }) => {
  const renderItemPurchase = (transaction, manifest) => {
    const providerName = prefillSelector(providerById)(manifest.get('provider').id).name;
    const manifestBody = getManifestBody(manifest);
    const title = getContentAsText(getTitle(manifestBody));

    return (
      <PurchaseRow
        key={transaction.id}
        href={`/item/${manifest.id}`}
        refund={transaction.get('type') === 'refund' || transaction.get('type') === 'auto-refund'}
        date={transaction.get('created_at')}
        text={`${providerName} - ${title}`}
        amount={transaction.get('amount')}
      />
    );
  };

  const renderIssuePurchase = (transaction, issue) => {
    const provider = prefillSelector(providerById)(issue.get('provider').id);
    const issueDateString = moment(issue.get('date'))
      .calendar()
      .toLowerCase();

    return (
      <PurchaseRow
        key={transaction.id}
        href={`/issue/${provider.id}/${issue.id}`}
        refund={transaction.get('type') === 'refund' || transaction.get('type') === 'auto-refund'}
        date={transaction.get('created_at')}
        text={`
          ${provider.name} - ' +
          ${translate('settings.wallet.transactions.issue', [issueDateString])}
        `}
        amount={transaction.get('amount')}
      />
    );
  };

  const renderPurchases = () =>
    purchases.map(transaction => {
      if (transaction.getEmbedded('manifest')) {
        return renderItemPurchase(transaction, transaction.getEmbedded('manifest'));
      }
      if (transaction.getEmbedded('issue')) {
        return renderIssuePurchase(transaction, transaction.getEmbedded('issue'));
      }
      return null;
    });

  return (
    <div className="v-purchases overview">
      <h2 className="title">{translate('settings.wallet.list_user_items')}</h2>
      <ul className="purchases-list list">{renderPurchases()}</ul>
      {hasMore && (
        <button type="button" className="lnk-loadmore" onClick={onLoadMore}>
          {translate('settings.wallet.load_more_user_items')}
        </button>
      )}
    </div>
  );
};

PurchasesList.propTypes = {
  purchases: PropTypes.object.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export default PurchasesList;
