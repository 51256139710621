import React from 'react';
import { translate } from 'instances/i18n';
import Link from 'components/Link';

const MicropaymentTopUpButton = () => (
  <p>
    <Link
      href="/payment"
      state={{ returnUrl: '/settings/wallet' }}
      className="btn btn-text btn-blendle-icon-green btn-increase-balance lnk-payment"
    >
      {translate('settings.buttons.increase_balance')}
    </Link>
  </p>
);

export default MicropaymentTopUpButton;
