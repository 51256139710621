import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function ToggleButton(props) {
  const toggleClasses = classNames(
    'l-toggle',
    'v-checkbox',
    { 'l-checked': props.checked },
    { 'l-unchecked': !props.checked },
    { 's-inactive': props.inactive },
    { [props.className]: props.className },
  );

  const btnClasses = classNames('btn-toggle', {
    's-inactive': props.inactive,
  });

  return (
    <div className={toggleClasses} onClick={props.onToggle}>
      <div className={btnClasses} />
    </div>
  );
}

ToggleButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  inactive: PropTypes.bool,
};

export default ToggleButton;
