import { useEffect, useState, useRef } from 'react';
import Settings from 'controllers/settings';
import DepositsCollection from 'collections/deposits';
import useUser from 'hooks/useUser';

const useDepositsCollection = () => {
  const user = useUser();
  const [syncCount, setSyncCount] = useState(0);

  // Using a ref to keep the same instance of the backbone collection throughout the renders
  const depositsCollectionRef = useRef(
    new DepositsCollection(null, {
      url: Settings.getLink('deposits', { user_id: user.id }),
    }),
  );

  useEffect(() => {
    depositsCollectionRef.current.fetch();
  }, []);

  useEffect(() => {
    depositsCollectionRef.current.on('sync', () => {
      // Updating a "random" state variable so that we trigger a new render
      // after the Backbone collection has been updated.
      setSyncCount(count => count + 1);
    });

    return () => {
      depositsCollectionRef.current.off();
    };
  }, [syncCount]);

  return {
    deposits: depositsCollectionRef.current,
    fetchMore: () => depositsCollectionRef.current.fetchNext(),
    hasMore: () => depositsCollectionRef.current.hasNext(),
  };
};

export default useDepositsCollection;
