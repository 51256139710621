// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media screen and (min-width: 600px){.dialog-2vyA0{width:500px !important}}.password-1C7--{width:100%}.reason-16EG1{text-align:left}.radiogroup-14I9S{margin-bottom:10px}.disabledButton-cmyiF,.disabledButton-cmyiF:hover{background-color:#ffc0bb;cursor:not-allowed}.otherReasonTextInputLabel-2rVzl{width:100%}.otherReasonTextInput-1jwxx{width:100%}.errorCodeMessage-2Tk-l{background-color:#e5e5e5;padding:1rem;margin:1rem 0;word-wrap:break-word;text-align:left}\n", ""]);
// Exports
exports.locals = {
	"dialog": "dialog-2vyA0",
	"password": "password-1C7--",
	"reason": "reason-16EG1",
	"radiogroup": "radiogroup-14I9S",
	"disabledButton": "disabledButton-cmyiF",
	"otherReasonTextInputLabel": "otherReasonTextInputLabel-2rVzl",
	"otherReasonTextInput": "otherReasonTextInput-1jwxx",
	"errorCodeMessage": "errorCodeMessage-2Tk-l"
};
module.exports = exports;
