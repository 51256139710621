import React, { Component } from 'react';
import { func, object, instanceOf } from 'prop-types';
import { translate } from 'instances/i18n';
import { getUserProperty } from 'selectors/user';
import CapabilityIsLocked from 'components/CapabilityIsLocked';
import { FEATURE_CAPABILITY_ALERTS, FEATURE_CAPABILITY_NEWSSTAND } from 'app-constants';
import { isLoading } from './helpers';
import Email from './Email';
import MasterOptOut from './MasterOptOut';

class Emails extends Component {
  renderLockedRow(capability, propertyName) {
    return (
      <CapabilityIsLocked capability={capability}>
        {({ capabilityIsLocked }) => !capabilityIsLocked && this.renderRow(propertyName)}
      </CapabilityIsLocked>
    );
  }

  renderRow(propertyName) {
    const { user, loadingProperties, onToggleOption } = this.props;

    const active = getUserProperty(user, propertyName);
    const loading = isLoading(user, loadingProperties, propertyName);

    return (
      <Email
        active={active}
        loading={loading}
        onToggleOption={() => onToggleOption(propertyName)}
        propertyName={propertyName}
      />
    );
  }

  render() {
    return (
      <div className="v-emails pane">
        <div className="container">
          <h2 className="header">{translate('settings.emails.header')}</h2>
          <h3 className="title">{translate('settings.emails.options.title_daily')}</h3>
          <ul>
            {this.renderLockedRow(FEATURE_CAPABILITY_ALERTS, 'alerts_opt_out')}
            {this.renderLockedRow(FEATURE_CAPABILITY_NEWSSTAND, 'new_edition_opt_out')}
            {this.renderRow('digest_opt_out')}
          </ul>
          <h3 className="title">{translate('settings.emails.options.title_weekly')}</h3>
          <ul>
            {this.renderRow('weekly_digest_opt_out')}
            {this.renderRow('magazine_digest_opt_out')}
          </ul>
          <h3 className="title">{translate('settings.emails.options.title_random')}</h3>
          <ul>
            {this.renderRow('read_later_opt_out')}
            {this.renderRow('marketing_opt_out')}
            {this.renderRow('tips_opt_out')}
            {this.renderRow('announcements_opt_out')}
            {this.renderRow('survey_opt_out')}
          </ul>
          <MasterOptOut user={this.props.user} onToggleOption={this.props.onToggleOption} />
        </div>
      </div>
    );
  }
}

Emails.propTypes = {
  onToggleOption: func.isRequired,
  user: object.isRequired, // eslint-disable-line react/forbid-prop-types
  loadingProperties: instanceOf(Set).isRequired,
};

export default Emails;
