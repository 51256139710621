import React from 'react';
import PurchasesList from '../PurchasesList';
import usePurchasesCollection from '../../hooks/usePurchasesCollection';

const Purchases = () => {
  const { purchases, fetchMore, hasMore } = usePurchasesCollection();

  const onLoadMore = e => {
    e.preventDefault();

    fetchMore();
  };

  return <PurchasesList purchases={purchases} hasMore={hasMore()} onLoadMore={onLoadMore} />;
};

export default Purchases;
