// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".disclaimer-2jyMf{margin-top:64px !important}@media (min-width: 600px){.disclaimer-2jyMf{margin-top:24px !important}}.bold-3A4Li{font-weight:600}.underline-1ml40{text-decoration:underline}\n", ""]);
// Exports
exports.locals = {
	"disclaimer": "disclaimer-2jyMf",
	"bold": "bold-3A4Li",
	"underline": "underline-1ml40"
};
module.exports = exports;
