import React from 'react';
import styledModule from 'helpers/styledModule';
import CSS from './styles.scss';

const Disclaimer = styledModule.p(CSS.disclaimer);
const Bold = styledModule.span(CSS.bold);
const Underline = styledModule.span(CSS.underline);

const PremiumPlusMicropaymentDisclaimer = () => (
  <Disclaimer>
    Jij gebruikt Blendle in combinatie met betalen-per-artikel. Blendle stopt op 31 december 2019
    met betalen-per-artikel,{' '}
    <Bold>
      maar voor jou blijft deze mogelijkheid <Underline>wél</Underline> bestaan
    </Bold>
    . Het tegoed uit je portemonnee hoef je dus niet op te maken en meer leestegoed aanschaffen
    blijft ook gewoon mogelijk.
  </Disclaimer>
);
export default PremiumPlusMicropaymentDisclaimer;
