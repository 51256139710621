import { compose, withProps } from '@blendle/recompose';
import altConnect from 'higher-order-components/altConnect';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import FeatureCapabilitiesActions from 'actions/FeatureCapabilitiesActions';
import capabilityList from './capabilities.json';
import capabilitySets from './capability-sets.json';
import Capabilities from '../../components/Capabilities';

const getValueName = (isForceAdded, isForceRemoved) =>
  [isForceAdded && 'add', isForceRemoved && 'remove', 'unset'].filter(Boolean)[0];

function mapStateToPropsForSingleCapability({ featureCapabilitiesState }, props) {
  const isForceAdded = featureCapabilitiesState.forceAddedCapabilities.has(props.name);
  const isForceRemoved = featureCapabilitiesState.forceRemovedCapabilities.has(props.name);
  const userHasCapability = featureCapabilitiesState.capabilities.has(props.name);

  const value = getValueName(isForceAdded, isForceRemoved);

  return {
    ...props,
    value,
    userHasCapability,
  };
}
mapStateToPropsForSingleCapability.stores = { FeatureCapabilitiesStore };

function mapStateToProps({ featureCapabilitiesState }, props) {
  const capabilities = props.capabilityList.map((capability) => ({
    ...capability,
    ...mapStateToPropsForSingleCapability({ featureCapabilitiesState }, capability),
  }));

  return { capabilities };
}
mapStateToProps.stores = { FeatureCapabilitiesStore };

export default compose(
  withProps({ capabilityList, capabilitySets }),
  altConnect(mapStateToProps, {
    setForceCapability: FeatureCapabilitiesActions.setForceCapability,
  }),
)(Capabilities);
