import React from 'react';
import { bool } from 'prop-types';
import { Button } from '@blendle/lego';
import DeleteAccountActions from 'actions/DeleteAccountActions';
import { translate } from 'instances/i18n';
import DeleteAccountDialogContainer from '../../containers/DeleteAccountDialogContainer';
import DeleteAccountSupportDialogContainer from '../../containers/DeleteAccountSupportDialogContainer';
import CSS from './style.scss';

function DeleteAccount({ mayDeleteAccount, hasNonClosableSubscription }) {
  return (
    <div className={CSS.deleteAccount}>
      {mayDeleteAccount ? (
        <div className={CSS.action}>
          <Button
            size="small"
            color="transparent"
            className={CSS.button}
            onClick={DeleteAccountActions.toggleDeleteAccountDialog}
          >
            {translate('settings.profile.delete_account.action')}
          </Button>
        </div>
      ) : null}
      {mayDeleteAccount ? <DeleteAccountDialogContainer /> : null}
      {hasNonClosableSubscription ? <DeleteAccountSupportDialogContainer /> : null}
    </div>
  );
}

DeleteAccount.propTypes = {
  mayDeleteAccount: bool.isRequired,
  hasNonClosableSubscription: bool.isRequired,
};

export default DeleteAccount;
