import React from 'react';
import { translate } from 'instances/i18n';
import styledModule from 'helpers/styledModule';
import BalanceLabel from 'components/BalanceLabel';
import CSS from './styles.scss';

const Wrapper = styledModule.div(CSS.wrapper);
const Title = styledModule.h2(CSS.title);
const StyledBalanceLabel = styledModule(BalanceLabel)(CSS.balanceLabel);

const Balance = () => (
  <Wrapper>
    <Title>{translate('settings.wallet.balance')}</Title>
    <StyledBalanceLabel data-testid="setting-wallet-balance-label" />
  </Wrapper>
);

export default Balance;
