import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import formatCurrency from 'helpers/formatCurrency';
import moment from 'moment';
import Link from 'components/Link';

function PurchaseRow({ refund, date, href, text, amount }) {
  const priceClassName = classNames('price', {
    refund,
  });

  return (
    <li className="transaction">
      <div className="date">{moment(date).calendar()}</div>
      <Link className="head" href={href} target="_self">
        <span className="provider">{text}</span>
        <span className={priceClassName} data-testid="price">
          &nbsp;
          {refund ? '+' : ''}
          {formatCurrency(amount)}
        </span>
      </Link>
    </li>
  );
}

PurchaseRow.propTypes = {
  href: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  refund: PropTypes.bool.isRequired,
};

export default PurchaseRow;
