// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".section-3OXWW{margin:2em 0}.row-1_CsQ{align-items:baseline}.nameColumn-16Uwx{flex:4}.name-1NJdD{font-weight:700}.dropDown-3Q4dV{flex:2;margin:0 1em}.currentState-Ilcu9{text-align:right}\n", ""]);
// Exports
exports.locals = {
	"section": "section-3OXWW",
	"row": "row-1_CsQ",
	"nameColumn": "nameColumn-16Uwx",
	"name": "name-1NJdD",
	"dropDown": "dropDown-3Q4dV",
	"currentState": "currentState-Ilcu9"
};
module.exports = exports;
