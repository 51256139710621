import React from 'react';
import { translate } from 'instances/i18n';
import useAlt from 'hooks/useAlt';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { hasCapability } from 'selectors/capabilities';
import {
  FEATURE_CAPABILITY_MICROPAYMENTS_TOPUP,
  FEATURE_CAPABILITY_BLENDLE_PREMIUM,
  FEATURE_CAPABILITY_MICROPAYMENTS_AUTO_TOPUP,
} from 'app-constants';
import useUser from 'hooks/useUser';
import EmailNotConfirmed from './EmailNotConfirmed';
import MicropaymentTopUpButton from './MicropaymentTopUpButton';
import PremiumPlusMicropaymentDisclaimer from './PremiumPlusMicropaymentDisclaimer';
import Balance from '../Balance';
import Recurring from './Recurring';
import Purchases from '../Purchases';
import Transactions from '../Transactions';

const MicropaymentWallet = () => {
  const user = useUser();
  const featureCapabilitiesState = useAlt([FeatureCapabilitiesStore], ([state]) => state);

  const isEmailConfirmed = user.get('email_confirmed');

  const hasTopUpCapability = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_MICROPAYMENTS_TOPUP,
  );

  const hasBlendlePremium = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_BLENDLE_PREMIUM,
  );

  const hasAutoTopUpCapability = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_MICROPAYMENTS_AUTO_TOPUP,
  );

  return (
    <div className="v-wallet pane">
      <div className="container">
        <h2 className="header">{translate('settings.wallet.title')}</h2>
        {isEmailConfirmed ? (
          <div className="block">
            <Balance data-testid="balance" />
            {hasTopUpCapability && <MicropaymentTopUpButton data-testid="manual-topUp" />}
            {hasBlendlePremium && (
              <PremiumPlusMicropaymentDisclaimer data-testid="premium-disclaimer" />
            )}
          </div>
        ) : (
          <EmailNotConfirmed data-testid="email-unconfirmed" />
        )}
        {hasAutoTopUpCapability && <Recurring data-testid="auto-topUp" />}
        <div className="overviews">
          <Purchases />
          <Transactions />
        </div>
      </div>
    </div>
  );
};

export default MicropaymentWallet;
