// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".balanceLabelBase-2YXX_{margin-left:5px;line-height:27px;position:relative;top:-1px}@media (max-width: 1024px){.balanceLabelDesktop-2ITug{margin-left:0;top:4px}}\n", ""]);
// Exports
exports.locals = {
	"balanceLabelBase": "balanceLabelBase-2YXX_",
	"balanceLabelDesktop": "balanceLabelDesktop-2ITug"
};
module.exports = exports;
