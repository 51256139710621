import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SubscriptionCancelDialog from './SubscriptionCancelDialog';
import SubscriptionDetailsDialog from './SubscriptionDetailsDialog';
import SubscriptionCard from './SubscriptionCard';

class SubscriptionsList extends PureComponent {
  static propTypes = {
    subscriptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedSubscription: PropTypes.object,
    onCancel: PropTypes.func,
    onLegacyCancel: PropTypes.func,
    onClickMoreInfo: SubscriptionCard.propTypes.onClickMoreInfo,
    onClickUpsell: SubscriptionCard.propTypes.onClickUpsell,
    onCloseDetails: SubscriptionDetailsDialog.propTypes.onDismiss,
    onUpdateReason: SubscriptionDetailsDialog.propTypes.onUpdateReason,
  };

  state = {
    showDialog: false,
  };

  onClickCardButton = subscription => {
    this.setState({ showDialog: true, subscription });
  };

  onClose = () => {
    this.setState({ showDialog: false });
  };

  renderSubscriptions() {
    return this.props.subscriptions.map(subscription => (
      <SubscriptionCard
        subscription={subscription}
        onClickCancelButton={this.onClickCardButton}
        onClickUpsell={this.props.onClickUpsell}
        onClickMoreInfo={this.props.onClickMoreInfo}
        key={subscription.uid}
      />
    ));
  }

  renderDisconnectDialog() {
    if (!this.state.showDialog) {
      return null;
    }

    return (
      <SubscriptionCancelDialog
        subscription={this.state.subscription}
        onClose={this.onClose}
        onCancel={this.props.onCancel}
        onLegacyCancel={this.props.onLegacyCancel}
      />
    );
  }

  renderDetailDialog() {
    const { selectedSubscription } = this.props;
    if (!selectedSubscription) {
      return null;
    }

    return (
      <SubscriptionDetailsDialog
        subscription={selectedSubscription}
        onDismiss={this.props.onCloseDetails}
        onCancelSubscription={this.props.onCancel}
        onUpdateReason={this.props.onUpdateReason}
      />
    );
  }

  render() {
    return (
      <div>
        <div className="v-subscriptions-list">
          {this.renderSubscriptions()}
          {this.renderDisconnectDialog()}
          {this.renderDetailDialog()}
        </div>
      </div>
    );
  }
}

export default SubscriptionsList;
