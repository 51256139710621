import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { compose, setDisplayName, withProps, wrapDisplayName } from '@blendle/recompose';
import { capabilityIsLocked } from 'selectors/capabilities';
import altConnect from './altConnect';

function mapStateToProps({ featureCapabilitiesState }, { capability, ...ownProps }) {
  return {
    ...ownProps,
    capabilityIsLocked: capabilityIsLocked(featureCapabilitiesState, capability),
  };
}

mapStateToProps.stores = { FeatureCapabilitiesStore };

export const addCapabilityIsLocked = altConnect(mapStateToProps);

const withCapabilityIsLocked = (capability) => (ComposedComponent) =>
  compose(
    setDisplayName(wrapDisplayName(ComposedComponent, 'withCapabilityIsLocked')),
    withProps({ capability }),
    addCapabilityIsLocked,
  )(ComposedComponent);

export default withCapabilityIsLocked;
