import React from 'react';
import TransactionsList from '../TransactionsList';
import useDepositsCollection from '../../hooks/useDepositsCollection';
import useOrdersCollection from '../../hooks/useOrdersCollection';

const Transactions = () => {
  const {
    deposits,
    hasMore: hasMoreDeposits,
    fetchMore: fetchMoreDeposits,
  } = useDepositsCollection();
  const { orders, hasMore: hasMoreOrders, fetchMore: fetchMoreOrders } = useOrdersCollection();

  const onLoadMore = e => {
    e.preventDefault();

    if (hasMoreDeposits()) {
      fetchMoreDeposits();
    }

    if (hasMoreOrders()) {
      fetchMoreOrders();
    }
  };

  return (
    <TransactionsList
      deposits={deposits}
      orders={orders}
      hasMore={hasMoreDeposits() || hasMoreOrders()}
      onLoadMore={onLoadMore}
    />
  );
};

export default Transactions;
