/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import formatCurrency from 'helpers/formatCurrency';
import moment from 'moment';
import { translate, getCurrency } from 'instances/i18n';
import TransactionRow from '../TransactionRow';

function localizedInterval(intervalString) {
  const number = parseInt(intervalString.replace(/[^0-9]/g, ''), 10);
  const str = intervalString.replace(/[0-9 ]/g, '');

  return moment.duration(number, str).humanize();
}

function getTierPrice(product) {
  return product._embedded['b:tier']._embedded['b:tier-prices'].find(
    tier => tier.currency === getCurrency(),
  ).amount;
}

function getOrderText(order) {
  if (!order.getEmbedded('product')) {
    return '';
  }

  const product = order.getEmbedded('product').get('_embedded')['b:subscription-product'];
  const key = product.trial
    ? 'transactions.order.subscription_trial'
    : 'transactions.order.subscription';

  return translate(key, {
    interval: localizedInterval(product.interval),
    name: product.name,
    price: formatCurrency(getTierPrice(product), { includeSymbol: true }),
  });
}

function getDepositText(deposit) {
  const balance = formatCurrency(deposit.get('amount'));
  return translate(`settings.wallet.deposits.${deposit.get('type')}`, [balance]);
}

const TransactionsList = ({ deposits, orders, onLoadMore, hasMore }) => {
  const getTransitions = () => {
    const depositsList = deposits.map(deposit => ({
      type: 'deposit',
      data: deposit,
    }));

    const ordersList = orders.map(order => ({
      type: 'order',
      data: order,
    }));

    return [...depositsList, ...ordersList].sort(
      (a, b) => new Date(b.data.get('created_at')) - new Date(a.data.get('created_at')),
    );
  };

  const renderItems = () =>
    getTransitions().map(item => {
      const date = item.data.get('created_at') && moment(item.data.get('created_at')).calendar();
      const text = item.type === 'order' ? getOrderText(item.data) : getDepositText(item.data);
      return <TransactionRow key={item.type + item.data.id} text={text} date={date} />;
    });

  return (
    <div className="v-transactions overview">
      <h2 className="title">{translate('settings.wallet.list_user_deposits')}</h2>
      <ul className="transactions-list list">{renderItems()}</ul>
      {hasMore && (
        <button type="button" className="lnk-loadmore" onClick={onLoadMore}>
          {translate('settings.wallet.load_more_user_deposits')}
        </button>
      )}
    </div>
  );
};

TransactionsList.propTypes = {
  deposits: PropTypes.object.isRequired, // byebye collections
  orders: PropTypes.object.isRequired, // byebye collections
  onLoadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export default TransactionsList;
