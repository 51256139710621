// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".buyCreditsButton-3ajtT{font-weight:500;margin-top:16px;max-width:250px;width:100%}\n", ""]);
// Exports
exports.locals = {
	"buyCreditsButton": "buyCreditsButton-3ajtT"
};
module.exports = exports;
