import React from 'react';
import { string, oneOf, func, bool, arrayOf, shape, objectOf } from 'prop-types';
import { Select, SelectOption, Columns, Rows, Button, ButtonGroup } from '@blendle/lego';
import styledModule from 'helpers/styledModule';

import CSS from './styles.scss';

const Section = styledModule.section(CSS.section);
const Header = styledModule.h2('header');
const CurrentState = styledModule.div(CSS.currentState);
const DropDownColumn = styledModule.div(CSS.dropDown);

function CapabilityOption({ capability, value: currentValue, onChange: handleChange }) {
  return (
    <Select
      selectedValue={currentValue}
      onSelectedValueChange={value => handleChange({ value, capability })}
    >
      <SelectOption value="unset">
        <span role="img" aria-label="">
          ⚪
        </span>{' '}
        Unset
      </SelectOption>
      <SelectOption value="add">
        <span role="img" aria-label="">
          ➕
        </span>{' '}
        Add
      </SelectOption>
      <SelectOption value="remove">
        <span role="img" aria-label="">
          ➖
        </span>{' '}
        Remove
      </SelectOption>
    </Select>
  );
}
CapabilityOption.propTypes = {
  capability: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
};

function Capability({ name, description, value, setForceCapability, userHasCapability }) {
  return (
    <Columns className={CSS.capabilityColumn}>
      <Rows className={CSS.nameColumn}>
        <div className={CSS.name}>{name}:</div>
        <div>{description}</div>
      </Rows>
      <DropDownColumn>
        <CapabilityOption capability={name} value={value} onChange={setForceCapability} />{' '}
      </DropDownColumn>
      <CurrentState>
        <input type="checkbox" checked={userHasCapability} disabled />
      </CurrentState>
    </Columns>
  );
}
Capability.propTypes = {
  name: string.isRequired,
  description: string.isRequired,
  value: oneOf(['unset', 'add', 'remove']).isRequired,
  setForceCapability: func.isRequired,
  userHasCapability: bool.isRequired,
};

const handleSetCapability = (setForceCapability, capabilities) => () => {
  Object.entries(capabilities).forEach(([capability, value]) =>
    setForceCapability({ capability, value }),
  );
};

export default function Capabilities({ capabilities, capabilitySets, setForceCapability }) {
  return (
    <div>
      <Section>
        <Header>Capability-Sets</Header>
        <ButtonGroup vertical>
          {capabilitySets.map(set => (
            <Button
              key={set.name}
              onClick={handleSetCapability(setForceCapability, set.capabilities)}
            >
              {set.name}
            </Button>
          ))}
        </ButtonGroup>
      </Section>
      <Section>
        <Header>Capabilities</Header>
        {capabilities.map(capability => (
          <Capability
            {...capability}
            setForceCapability={setForceCapability}
            key={capability.name}
          />
        ))}
      </Section>
    </div>
  );
}
Capabilities.propTypes = {
  capabilities: arrayOf(
    shape({
      name: string.isRequired,
      description: string.isRequired,
      value: string.isRequired,
      userHasCapability: bool.isRequired,
    }).isRequired,
  ).isRequired,
  capabilitySets: arrayOf(
    shape({
      name: string.isRequired,
      description: string.isRequired,
      capabilities: objectOf(oneOf(['add', 'remove', 'unset'])).isRequired,
    }),
  ).isRequired,
  setForceCapability: func.isRequired,
};
