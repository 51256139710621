// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialog-2S2V3{text-align:left}\n", ""]);
// Exports
exports.locals = {
	"dialog": "dialog-2S2V3"
};
module.exports = exports;
