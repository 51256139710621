import React from 'react';
import { STATUS_ERROR } from 'app-constants';
import ErrorDialogue from 'components/dialogues/ErrorDialogue';
import DialoguesController from 'controllers/dialogues';
import AuthStore from 'stores/AuthStore';
import altConnect from 'higher-order-components/altConnect';
import AuthActions from 'actions/AuthActions';
import { getUserProperty } from 'selectors/user';
import Emails from '../components/Emails';

function onError(error) {
  const onClose = DialoguesController.openComponent(<ErrorDialogue onClose={() => onClose()} />);
  throw error;
}

function mapStateToProps({ authState }) {
  const { user, loadingProperties, status, error } = authState;

  if (status === STATUS_ERROR) {
    onError(error);
  }

  return {
    user,
    loadingProperties,
  };
}
mapStateToProps.stores = { AuthStore };

function onToggleOption(propertyName) {
  const { user, loadingProperties } = AuthStore.getState();
  const newValue = !getUserProperty(user, propertyName);

  if (!loadingProperties.has(propertyName)) {
    AuthActions.updateUserProperty(user, propertyName, newValue);
  }
}

const actions = { onToggleOption };

export default altConnect(mapStateToProps, actions)(Emails);
