import React from 'react';
import { translate, translateElement } from 'instances/i18n';

const EmailNotConfirmed = () => (
  <div className="block unconfirmed">
    <p className="warn">{translate('settings.wallet.unconfirmed_warning')}</p>
    <p>{translateElement('settings.wallet.unconfirmed')}</p>
  </div>
);

export default EmailNotConfirmed;
